import ExternalLink from '../../components/ExternalLink';

export default function TrackPronouns() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Track Pronouns
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://openplanet.dev/plugin/trackpronouns" className="header text-3xl hover:underline focus:underline">
                    <p className="mb-5">
                        Plugin Link
                    </p>
                </ExternalLink>
                <p>
                    A trackmania plugin to add pronouns to tracks.
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    I was inspired by the <ExternalLink href="https://geode-sdk.org/mods/n.level_pronouns/" className="hover:underline focus:underline">
                        Geometry Dash Pronouns Mod
                    </ExternalLink> and wanted to make the same thing but for Trackmania.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    Openplanet uses <ExternalLink href="https://angelcode.com/angelscript/" className="hover:underline focus:underline">
                        Angelscript
                    </ExternalLink> for scripting, which has C/C++ syntax. The pronouns themselves
                    are deterministic, using the level name and author name to ensure that the
                    pronouns remain consistent.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    The end result is pretty much exactly what I was hoping for, so I am satisfied. ^w^
                </p>
            </div>
        </div>
    );
}
