import ExternalLink from '../../components/ExternalLink';

export default function ANOWebsite() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Titan's Valor Website
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://titansvalor.org" className="header text-3xl hover:underline focus:underline">
                    <p>
                        https://titansvalor.org
                    </p>
                </ExternalLink>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    The old website for my guild had several flaws:
                </p>
                <ol className="text-left my-2">
                    <li>1) No responsive design whatsoever </li>
                    <li>2) The index leaderboard was hardcoded </li>
                    <li>3) No dark mode support </li>
                </ol>
                <p>
                    A copy of this old version has been preserved at <ExternalLink href="https://titantimes.github.io/" className="hover:underline focus:underline">
                        https://titantimes.github.io/
                    </ExternalLink> to see these faults as they appeared.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    Originally, I was using <code>create-react-app</code> to develop the project, but switched
                    to <code>Next.js</code> after the former became unmaintained (this was causing issues). Since
                    there was already a design from the old website, I focused on optimizing the design to be more visually
                    pleasing, whilst also fixing the previously mentioned issues.
                </p>
                <p className="mb-5">
                    The leaderboard portion gave me good experience handling databases on websites, expanding on what I already
                    learned from my work on VFox. Plus, it's a completely novel feature from the old website, and is in some ways
                    better than the official Wynncraft leaderboards.
                </p>
                <p>
                    The database and website hosting uses the guild's dedicated server which is maintained by Aezuh.
                    While this is a convenience in that I don't need to worry about the specifics behind that,
                    I do wish that I knew more about it.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p className="mb-5">
                    I find it funny how the guild website is significantly more developed and pretty than my own
                    website. Granted, the next entry in my to-do list is to take a deeper look at my website, but I think it just goes to show
                    how much I have grown as a front-end programmer and enhanced my skills.
                </p>
            </div>
        </div>
    );
}
