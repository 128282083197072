import ExternalLink from '../components/ExternalLink';

import { ReactComponent as CBLogo } from '../assets/site-icons/codeberg-icon.svg';
import MDLogo from '../assets/site-icons/mastodon-icon.png';
import { ReactComponent as YTLogo } from '../assets/site-icons/youtube-icon.svg';
import { ReactComponent as DCLogo } from '../assets/site-icons/discord-icon.svg';
import { ReactComponent as EMLogo } from '../assets/site-icons/email-icon.svg';



function contactBox(img, name, link, reasoning) {
    return (
        <li className="box text-center mb-10 w-full max-w-xs m-4">
            <ExternalLink href={link} title={name} className="flex justify-center mb-4">
                {img}
            </ExternalLink>
            <p>
                {reasoning}
            </p>
        </li>
    );
}


export default function Contact() {
    return (<>
        <h1 className="header mb-20">
            Contact Me
        </h1>
        <ul className="flex flex-wrap justify-center">
            {contactBox(<CBLogo className="to-beige w-32 h-32" />, "Codeberg", "https://codeberg.org/FoxSylv", "...about my code!")}
            {contactBox(<img src={MDLogo} alt="Mastodon Logo" className="to-beige w-32 h-32" />, "Mastodon", "https://blobfox.coffee/@foxsylv", "...about myself!")}
            {contactBox(<YTLogo className="to-beige w-32 h-32" />, "Youtube", "https://www.youtube.com/channel/UCj7lR9rm06lCxE4kxkJeECA", "...about my videos!")}
            {contactBox(<DCLogo className="to-beige w-32 h-32" />, "Discord", "https://discord.com/users/294814074670284800", "...in private!")}
            {contactBox(<EMLogo className="to-beige w-32 h-32" />, "Email", "mailto:fox@foxsylv.dev", "Email never fails!")}
        </ul>
    </>);
}
