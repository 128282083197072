/* Thumbnails */
import ANOThumbnail from '../assets/project-thumbnails/ANO.png';
import trackPronounsThumbnail from '../assets/project-thumbnails/track-pronouns.jpg';
import mouseSteeringThumbnail from '../assets/project-thumbnails/mousesteering.png';
import MCSpeakerThumbnail from '../assets/project-thumbnails/mc-speaker.jpg';
import VFoxThumbnail from '../assets/project-thumbnails/vfox.png';
import HLPThumbnail from '../assets/project-thumbnails/hlp.png';
import websiteThumbnail from '../assets/project-thumbnails/website.png';
import foxpcThumbnail from '../assets/project-thumbnails/fox-pc.jpg';
import nonbegoneThumbnail from '../assets/project-thumbnails/nonbegone.png';
import ttplusThumbnail from '../assets/project-thumbnails/ttplus.png';
import remindersThumbnail from '../assets/project-thumbnails/reminders.png';
import fox0Thumbnail from '../assets/project-thumbnails/fox-0.png';
import lettergetterThumbnail from '../assets/project-thumbnails/letter-getter.png';

/* Routes */
import ANOWebsite from '../routes/projects/ANOWebsite';
import TrackPronouns from '../routes/projects/TrackPronouns';
import MouseSteering from '../routes/projects/MouseSteering';
import MCSpeaker from '../routes/projects/MCSpeaker';
import VFox from '../routes/projects/VFox';
import HLP from '../routes/projects/HLP';
import Website from '../routes/projects/Website';
import FoxPC from '../routes/projects/FoxPC';
import NonBeGone from '../routes/projects/NonBeGone';
import TTPlus from '../routes/projects/TTPlus';
import Reminders from '../routes/projects/Reminders';
import Fox0 from '../routes/projects/Fox0';
import LetterGetter from '../routes/projects/LetterGetter';


export function getProjects() {
    return [
        {name: "ANO Website", link: "titans-valor-website", route: <ANOWebsite />, thumbnail: <img src={ANOThumbnail} alt="" />, description: "Website for the Wynncraft guild ANO"},
        {name: "Track Pronouns", link: "track-pronouns", route: <TrackPronouns />, thumbnail: <img src={trackPronounsThumbnail} alt="" />, description: "Trackmania track pronouns"},
        {name: "Mouse Steering", link: "mouse-steering", route: <MouseSteering />, thumbnail: <img src={mouseSteeringThumbnail} alt ="" />, description: "Steering with mouse movement"},
        {name: "MC Speaker", link: "mcspeaker", route: <MCSpeaker />, thumbnail: <img src={MCSpeakerThumbnail} alt="" />, description: "10Hz programmable speaker in Minecraft"},
        {name: "VFox Bot", link: "vfox", route: <VFox />, thumbnail: <img src={VFoxThumbnail} alt="" />, description: "A discord game about collecting foxes"},
        {name: "HLP", link: "hlp", route: <HLP />, thumbnail: <img src={HLPThumbnail} alt="" />, description: "A brute-force algorithm for the HLP"},
        {name: "My Website", link: "website", route: <Website />, thumbnail: <img src={websiteThumbnail} alt="" />, description: "A foray into learning web development"},
        {name: "Fox-PC", link: "fox-pc", route: <FoxPC />, thumbnail: <img src={foxpcThumbnail} alt="" />, description: "My real-life workstation"},
        {name: "NonBeGone", link: "nonbegone", route: <NonBeGone />, thumbnail: <img src={nonbegoneThumbnail} alt="" />, description: "Utility mod for Hypixel Skyblock"},
        {name: "TT+", link: "ttplus", route: <TTPlus />, thumbnail: <img src={ttplusThumbnail} alt="" />, description: "Mod for GD Colon's Treacherous Trials"},
        {name: "Reminders", link: "reminders", route: <Reminders />, thumbnail: <img src={remindersThumbnail} alt="" />, description: "Online to-do list made from XML"},
        {name: "Fox-0", link: "fox-0", route: <Fox0 />, thumbnail: <img src={fox0Thumbnail} alt="" />, description: "0.4Hz CPU built in Minecraft"},
        {name: "Letter Getter", link: "letter-getter", route: <LetterGetter />, thumbnail: <img src={lettergetterThumbnail} alt="" />, description: "Daily grid-based anagram game"}
    ];
}
