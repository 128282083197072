import { useRouteLoaderData } from 'react-router-dom';
import ExternalLink from '../components/ExternalLink';
import DownArrow from '../components/DownArrow';
import ProjectBox from '../components/ProjectBox';

import { ReactComponent as CBLogo } from '../assets/site-icons/codeberg-icon.svg';
import MDLogo from '../assets/site-icons/mastodon-icon.png';
import { ReactComponent as YTLogo } from '../assets/site-icons/youtube-icon.svg';


function socialIcon(href, title, img) {
    return (
        <ExternalLink href={href} title={title} className="mx-3 hover:-translate-y-2 transition-transform duration-100">
            {img}
        </ExternalLink>
    );
}


export default function Index() {
    const projects = useRouteLoaderData("root");
    var featuredProjectNames = ["ANO Website", "VFox Bot", "Fox-0"];
    var featuredIndices = featuredProjectNames.map(name => projects.findIndex(p => p.name === name));

    return (<>
        {/* Title */}
        <div className="text-center mb-3 md:w-1/2 md:inline-block">
            <h1 className="header">
                FoxSylv
            </h1>
            <h2 className="md:ml-16">
                nya nya foxgirl uwu
            </h2>
        </div>

        {/* Social Icons */}
        <div className="flex md:inline-flex justify-center md:w-1/2 mb-48">
            {socialIcon("https://codeberg.org/FoxSylv", "Codeberg", <CBLogo alt="Codeberg Logo" className="to-beige w-16 h-16"/>)}
            {socialIcon("https://blobfox.coffee/@foxsylv", "Mastodon", <img src={MDLogo} alt="Mastodon Logo" className="to-beige w-14 h-14 mt-1" />)}
            {socialIcon("https://www.youtube.com/channel/UCj7lR9rm06lCxE4kxkJeECA", "Youtube", <YTLogo alt="Youtube Logo" className="to-beige w-16 h-16" />)}
        </div>

        {/* Welcome Message */}
        <div className="text-center mb-96">
            <p className="header text-6xl">
                Hello!
            </p>
            <p className="text-3xl">
               Welcome to my website! 
            </p>
        </div>

        {/* Down Arrow */}
        <DownArrow />
        
        {/* Project Listing */}
        <div className="mb-64">
            <h1 className="header mb-10">
                Featured Projects
            </h1>
            <div className="flex flex-wrap justify-center">
                {featuredIndices.map(fi => <ProjectBox project={projects[fi]} linkOverride={"projects/" + projects[fi].link} key={projects[fi].name}/>)}
            </div>
        </div>
        
        {/* About Me */}
        <div>
            <h1 className="header mb-16">
                About Me
            </h1>
            <p className="mb-8">
                Hi, my name is Aurora, although most people online call me "Fox".
                <br />
                My pronouns are <span className="trans-rights">she/her/hers</span>.
            </p>
            <p className="mb-8">
                Most of what you'll find on this website is stuff I do
                in my free time. There's a lot of funny little projects on here along with
                some of my accomplishments in video games (mainly speedrunning).
            </p>
            <p className="mb-8">
                I believe everyone should be welcome in this world, both in the tech sphere and beyond.
                I try to live freely and create things others can enjoy, while emphasizing
                diversity, inclusion, and understanding.
            </p>
            <p>
                Here's a fun fact, by the way. The anthropomorphic fox I use as my mascot is
                actually a lesser-known Linux mascot called Xenia! She's a trans woman
                as well, so I thought it would be a good fit for representing me, especially given my online handle :)
            </p>
        </div>
    </>);
}
