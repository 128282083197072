import ExternalLink from '../../components/ExternalLink';

export default function MouseSteering() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Mouse Steering
            </h1>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    I play <ExternalLink href="https://www.trackmania.com" className="hover:underline focus:underline">
                        Trackmania
                    </ExternalLink>, a racing game wherein there are many
                    tricks that are most optimal on an analog input device.
                    Since I used my mouse buttons to steer like a keyboard,
                    I could not adequately perform these tricks to the level I
                    would have liked.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    I used uinput to turn the mouse movements into a virtual
                    joystick. This idea is not new (in fact, quite a few
                    other folks have already made similar tools), but I made
                    my own personal one for maximal control.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    While not an overly complicated tool, I had fun making it
                    and use it every now and then in Trackmania tracks when
                    I need to pull off a trick that requires it.
                </p>
            </div>
        </div>
    );
}
