import ExternalLink from '../../components/ExternalLink';

export default function MCSpeaker() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Minecraft Speaker
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <div className="relative h-0 pb-[56.25%]">
                    <iframe src="https://www.youtube-nocookie.com/embed/25r8S8wN8Wg" title="Youtube video player" className="absolute top-0 left-0 w-full h-full"></iframe>
                </div>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    This project was part of a wireless radio project that I
                    was working on with <ExternalLink href="https://www.youtube.com/@iPlayGamesX" className="hover:underline focus:underline">
                        AhaWarrior1
                    </ExternalLink>, until we parted ways. His focus was on the
                    wireless tech, while my focus was on the music player. This
                    speaker is my final product.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    To achieve 10Hz playback, the speaker makes heavy use of
                    a technical quirk called comparator priming. Put simply,
                    by manipulating subtick ordering and a lag optimization in
                    the code for comparators, it is possible to have
                    comparators process inputs instantly.
                </p>
                <p className="mb-5">
                    The noteblock arrangement itself also has a clever trick
                    whereby instead of <em>powering</em> a noteblock to trigger
                    it, they are instead powered by default, using a precise
                    <em>depowering</em> to target a noteblock to power, and
                    thus play, the next redstone tick. This allows for the
                    noteblock array to become siginificantly more compact.
                </p>
                <p>
                   The piano and instrument selector device are not required
                   for the speaker to function. They are just there for
                   demonstration purposes. If you look, they simply pipe a
                   certain signal strength into the device which could be
                   input by anything.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    I'm quite prous of how compact the final result is. My
                    former partner on the project did not appreciate that I
                    finished the project by myself though, unfortunately.
                    Ideally, I would have also made a MIDI to Minecraft RAM
                    program for the final demonstration, but I was (and still
                    am) not having fun with redstone any longer, so it is left
                    as an exercise for the reader!
                </p>
            </div>
        </div>
    );
}
